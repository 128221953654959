<form [formGroup]="allReportForm" #ngODForm="ngForm" (ngSubmit)="searchFormSubmitted();">
  <div class="error" *ngIf="allReportForm.controls.asOf.errors?.mindate">Date must be after {{allReportForm.controls.asOf.errors?.mindate}} </div>
  <div class="row overage-comp">
    <div [class]="searchFormWidthCssClass">
      <bmt-client-search allSubClientsSelectFeature="true" (clientSelected)="setClientId($event)"
      disableReset="true"
      [selectedAdminPlan]="selectedAdminPlan"
      (clearedField)="resetField($event)"
      [disableSubClient]="!allReportForm.controls['subclientSpecifiedId'].enabled"
      [disableAsOf]="!allReportForm.controls['asOf'].enabled"
      (subClientSelected)="getSubClientDetails($event)"
      (selectedAllSubClients)="setAllSubClients()"
      (clientSelected)="setClientId($event)"
      [defaultToAllSubGroups]="true"
      [initialAsOfDate]="initialAsOfDate"
      (asOfSelected)="setAsOfDate($event)"
      [resetEvent]="resetSubject.asObservable()">
      </bmt-client-search>
    </div>
    <div class="col-md-3" *ngIf="reportType === 'Adhoc Report'">
    </div>
    <div class="col-md-3" *ngIf="allReportForm.controls['month'].enabled">
      <rs-select formControlName="month" label="Month" validation="eager" [items]="monthOptions">
      </rs-select>
    </div>
    <div class="col-md-3" *ngIf="allReportForm.controls['year'].enabled">
      <rs-select formControlName="year" label="Year" validation="eager" [items]="yearsOptions">
      </rs-select>
    </div>
    <div class="col-md-3" *ngIf="allReportForm.controls['startOfAgeRange'].enabled">
      <rs-input formControlName="startOfAgeRange" label="Start of Age Range" validation="eager"></rs-input>
    </div>
    <div class="col-md-3" *ngIf="allReportForm.controls['uptoAgeRange'].enabled">
      <rs-input formControlName="uptoAgeRange" label="Up to, but not including, age" validation="eager"></rs-input>
    </div>
  </div>
  <div class="row overage-comp">
    <div class="col overage-buttons">
      <button rs-button type="button" (click)="ngODForm.resetForm(); resetReportForm();">Reset</button>
      <button [disabled]="isFetching || !allReportForm.valid" rs-raised-button color="primary">Get Report</button>
    </div>
  </div>
</form>
<ng-container *ngIf="isFetching">
  <rs-loader></rs-loader>
</ng-container>
<div class="overage-content" *ngIf="isFormSubmitted && ngODForm.submitted && allReportForm.valid && !isFetching">
  <div class="error-message" *ngIf="errors">{{errors}}</div>

  <table>
    <thead>
      <tr>
        <th>{{reportType | titlecase}}</th>
      </tr>
    </thead>
    <tbody>
      <ng-container [ngSwitch]="reportType">
        <ng-container *ngSwitchCase="'Adhoc Report'">
          <ng-container *ngIf="adhocReportUrl; else noResults">
            <tr>
              <td>
                <a role="button" tabindex="0" (keydown.enter)="adhocReportUrl" [href]="adhocReportUrl" download="adhoc-report.xlsx">Download the List of Overage Dependents
                  <span class="svg-holder">
                    <svg class="svg-icon" viewBox="0 0 20 20">
                      <path fill="none"
                        d="M9.634,10.633c0.116,0.113,0.265,0.168,0.414,0.168c0.153,0,0.308-0.06,0.422-0.177l4.015-4.111c0.229-0.235,0.225-0.608-0.009-0.836c-0.232-0.229-0.606-0.222-0.836,0.009l-3.604,3.689L6.35,5.772C6.115,5.543,5.744,5.55,5.514,5.781C5.285,6.015,5.29,6.39,5.522,6.617L9.634,10.633z">
                      </path>
                      <path fill="none"
                        d="M17.737,9.815c-0.327,0-0.592,0.265-0.592,0.591v2.903H2.855v-2.903c0-0.327-0.264-0.591-0.591-0.591c-0.327,0-0.591,0.265-0.591,0.591V13.9c0,0.328,0.264,0.592,0.591,0.592h15.473c0.327,0,0.591-0.264,0.591-0.592v-3.494C18.328,10.08,18.064,9.815,17.737,9.815z">
                      </path>
                    </svg>
                  </span></a>
              </td>
            </tr>
          </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'Notice'">
          <ng-container *ngIf="notices.length; else noResults">
            <tr *ngFor="let notice of notices">
              <td><a role="button" tabindex="0" (keydown.enter)="getODNoticeDoc.emit(notice)" (click)="getODNoticeDoc.emit(notice)">Overage notice - group {{notice.clientSpecifiedId}} -
                  subgroup
                  {{notice.subclientSpecifiedId}} - {{notice.dateGenerated | date: 'MM/dd/yyyy'}}</a></td>
            </tr>
          </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'Term Consolidated Report'">
          <ng-container *ngIf="consolidatedReportUrl; else noResults">
            <tr>
              <td>
                <a role="button" tabindex="0" (keydown.enter)="consolidatedReportUrl" [href]="consolidatedReportUrl" download="term-report.xlsx">Download the List of Overage Dependents
                  <span class="svg-holder">
                    <svg class="svg-icon" viewBox="0 0 20 20">
                      <path fill="none"
                        d="M9.634,10.633c0.116,0.113,0.265,0.168,0.414,0.168c0.153,0,0.308-0.06,0.422-0.177l4.015-4.111c0.229-0.235,0.225-0.608-0.009-0.836c-0.232-0.229-0.606-0.222-0.836,0.009l-3.604,3.689L6.35,5.772C6.115,5.543,5.744,5.55,5.514,5.781C5.285,6.015,5.29,6.39,5.522,6.617L9.634,10.633z">
                      </path>
                      <path fill="none"
                        d="M17.737,9.815c-0.327,0-0.592,0.265-0.592,0.591v2.903H2.855v-2.903c0-0.327-0.264-0.591-0.591-0.591c-0.327,0-0.591,0.265-0.591,0.591V13.9c0,0.328,0.264,0.592,0.591,0.592h15.473c0.327,0,0.591-0.264,0.591-0.592v-3.494C18.328,10.08,18.064,9.815,17.737,9.815z">
                      </path>
                    </svg>
                  </span></a>
              </td>
            </tr>
          </ng-container>
        </ng-container>
        <ng-container *ngSwitchDefault></ng-container>
      </ng-container>
      <ng-template #noResults>
        <tr>
          <td class="no-results"><label>No {{reportType | titlecase}} Found</label></td>
        </tr>
      </ng-template>
    </tbody>
  </table>
</div>
<!--<a [href]="sanContent" download="file.pdf">DownloadFile</a>-->
