<div class="row pg-style">
  <div class="col-sm-12 pg-style">
    <div class="row search-banner">
      <div class="col-sm-12 search-banner-col">
        <h2 class="h3 search-banner-title">Summary Of Changes</h2>
      </div>
    </div>
    <div class="row search-nav">
      <div class="col-sm-12 search-nav-col">
        <ul class="list-inline search-nav-list">
          <li><a role="button" tabindex="0" (keydown.enter)="toggle(true)" (click)="toggle(true)" [class.search-nav-active]='session'>Session</a></li>
          <li><a role="button" tabindex="0" (keydown.enter)="toggle(false)" (click)="toggle(false)" [class.search-nav-active]='!session'>Previous Transactions</a></li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="row transactions-search-comp">
  <div class="col-sm-12 card">
    <ng-container *ngIf="isFetching">
      <rs-loader></rs-loader>
    </ng-container>
    <ng-container *ngIf="!session && !showDetails">
      <client-search [selectedAdminPlan]="selectedAdminPlan" [features]="['basic', 'advanced']"
                     (resetSelected)="clientSearchReset($event)"></client-search>
      <ng-container *ngIf="!(selectedSubClients && selectedSubClients.length && !backToResults)">
        <client-search-results [hideContentOnNoResults]="true" (subClientSelected)="subClientSelected($event)">
        </client-search-results>
      </ng-container>
      <ng-container *ngIf="selectedSubClients && selectedSubClients.length && !backToResults">
        <div class="selected-group-info">
          <div class="group-content" (click)="backToSearchResults()">
            <b>Group: </b><span>{{selectedSubClients[0].planAcronym}} {{selectedSubClients[0].client.specifiedId}} {{selectedSubClients[0].client.name}} </span>
            <b>Subgroup: </b><span>{{selectedSubClients[0].specifiedId}} {{selectedSubClients[0].name}}</span>
          </div>
        </div>
        <form [formGroup]="dateForm" (ngSubmit)="onSearchSubmitted()">
          <div class="row date-form">
            <div class="col-sm-2">
              <rs-input formControlName="startDate" type="date" placeholder="MM/DD/YYYY" label="From Date" validation="eager"></rs-input>
            </div>
            <div class="col-sm-2">
              <rs-input formControlName="endDate" type="date" placeholder="MM/DD/YYYY" label="To Date" validation="eager"></rs-input>
            </div>
            <div class="col-sm-2 date-submit-button">
              <button [disabled]="isFetching || !dateForm.valid" rs-raised-button color="primary">Submit</button>
            </div>
          </div>
        </form>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="(session || isSearched) && !showDetails">
      <rs-paginator showFirstLastButtons [length]="transactionsRecords?.totalRecords"
                     (page)="pageChanged($event)" [pageSize]="defaultPageSize" [maxSize]="2"></rs-paginator>
      <app-user-transactions [transactions]="transactions" (selectedTransaction)="selectedTransaction($event)"></app-user-transactions>
    </ng-container>
    <ng-container *ngIf="showDetails"><app-summary-details (backToTransactions)="backToTransactions($event)"></app-summary-details></ng-container>
  </div>
</div>

