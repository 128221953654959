<section style="height: 100%">
  <div style="height: 100%;" class="row-flex header-block header-bg">
    <header class="col login-1">
      <div class="logo-col">
        <a class="logo-delta-white" [class.logo-ren-white]="renPlan"><span class="sr-only">logo</span></a>
        <p class="log-into">Log in to</p>
        <h1 class="h3">Benefit Manager Toolkit &reg;</h1>
        <!--        <p>Don't have an account? <span style="font-weight: bold; font-size: 16px;">Contact your Account Manager to get started!</span></p>-->
        <div *ngIf="loginPageContent" class="login-page-content" [innerHTML]="loginPageContent"></div>
      </div>
    </header>
    <main class="auth-container main-container no-beacon col login-2" id="MainContent">
      <div class="d-flex landing justify-content-around">
        <div class="d-flex landing justify-content-around">
          <div class="row g-0 justify-content-around align-items-center">
            <div class="login-container">
              <rs-sign-in imageSrc="{{renPlan ? '../assets/images/logo-ren.svg' : '../assets/images/logo-delta.svg'}}"
                          [showForgotPassword]=true></rs-sign-in>
              <div class='forgot-password' >
                <a class="form-content login-links last-links" role="button" tabindex="0" (keydown.enter)="firstTimeLogin()" (click)="firstTimeLogin()">First Time Login?</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <rs-dialog [visible]="isFirstTimeLogin" (visibleChange)="isFirstTimeLogin = false" [baseZIndex]="99" heading="First Time Login">
      <div style="padding: 35px;">
        <h5>On the login page, please enter your username and click on next and proceed to the Forgot Password workflow.
          This will allow you to create a unique password, so that you can get started using the Benefit Manager
          Toolkit.</h5>
      </div>
    </rs-dialog>
    <rs-dialog [visible]="showRegistration" (visibleChange)="showRegistration = false" heading="Account Registration">
      <div style="padding: 62px;">
        <h5>Select your user type to produce the appropriate registration form to download</h5>
        <rs-select [items]="userTypes" label="User Type"></rs-select>
        <rs-select label="State"></rs-select>
      </div>
      <div class="registration-btns">
        <button class="cancel">CANCEL</button>
        <button class="download">DOWNLOAD REGISTRATION FORM</button>
      </div>
    </rs-dialog>
  </div>
</section>
