<div class="row pg-style">
  <div class="col-sm-12 pg-style">
    <div class="row search-banner">
      <div class="col-sm-12 search-banner-col">
        <h2 class="h3 search-banner-title">Client Knowledge Reports</h2>
        <rs-loader *ngIf="isFetching"></rs-loader>
      </div>
    </div>
    <div class="row search-nav">
      <div class="col-sm-12 search-nav-col"></div>
    </div>
    <div class="row search-comp" style="height: 100px;">
      <div class="card col-sm-12">
        <div style="margin: 10px;">
          <div class ="error" *ngIf="error">{{error}}</div>
          <div><a class="form-content link" style="cursor: pointer;" role="button" tabindex="0" (keydown.enter)="emitOpenClientKnowledge()" (click)="emitOpenClientKnowledge()">Client Knowledge Reports</a> should launch in a new window, If it does not, you can also launch it by clicking on the Client Knowledge Reports link.</div>
          </div>
      </div>
    </div>
  </div>
</div>
