<div class="bmt-profile-authorizations-container">
    <rs-loader *ngIf="fetchingClients"></rs-loader>
    <p *ngIf="errorsFetchingClients" class="error">{{errorsFetchingClients}}</p>
    <ng-container *ngIf="groupData && groupData.length">
        <table>
            <thead>
                <th>Admin Plan</th>
                <th>Group Name</th>
                <th>Group Id</th>
                <th>Authorizations</th>
            </thead>
            <tbody>
                <tr *ngFor="let group of groupData">
                    <td>{{group.planAcronym}}</td>
                    <td>{{group.name}}</td>
                    <td>{{group.specifiedId}}</td>
                    <td ><a role="button" tabindex="0" (keydown.enter)="openSubGroupAuths(group)" (click)="openSubGroupAuths(group)">View Subgroup Authorizations</a></td>
                </tr>
            </tbody>
        </table>
    </ng-container>
    <rs-paginator [length]="totalRecords" showFirstLastButtons
                        (page)="getMoreClients($event)" showFirstLastButtons
                        [pageSize]="defaultPageSize"></rs-paginator>
    <ng-container *ngIf="selectedClient">
        <rs-dialog maxWidth="80%" visible="true" (visibleChange)="selectedClient = ''" heading="Subgroup Authorizations">
            <div class="sub-group-auths">
                <bmt-view-auth-at-group [userContext]="selectedAdminPlan+'/'+groupSelected.planAcronym+'/'+selectedClient"></bmt-view-auth-at-group>
            </div>
        </rs-dialog>
    </ng-container>
</div>
